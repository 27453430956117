//审批列表
<template>
  <div
    class="parcel padding-lr-xl"
    style="height: calc(100vh - 80px); overflow: auto; margin-top: 90px"
  >
    <div class="immobilization">
      <div class="radioxz">
        <div>
          <el-radio v-model="radio" label="0">待审批</el-radio>
          <el-radio v-model="radio" label="1">已审批</el-radio>
          <el-radio v-model="radio" label="2">已拒绝</el-radio>
        </div>
        <div class="block" style="margin-left: 100px">
          <span class="demonstration" style="margin-right: 10px">选择日期</span>
          <el-date-picker
            v-model="selectionTime"
            type="month"
            @change="timing"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="cxbd" @click="inquire">查询</div>
        <div class="cxbd" @click="replacement">重置</div>
      </div>
    </div>

    <div class="monthList padding-top">
      <!--      <template v-for="(item, index) in monthList" :key="index">-->
      <!--        <div class="margin-bottom">{{ index }}</div>-->
      <!--        <div v-for="(info,i) in item" :key="i" @click="onRouterInfo(info)" class="monthList-info flex padding  flex align-center justify-between padding-right-xl cursor-pt">-->
      <!--          <div class="flex align-center">-->
      <!--            <div class="monthList-avatar  flex align-center justify-center">-->
      <!--              <img v-if="info?.avatar" :src="info.avatar" />-->
      <!--              <span v-else> {{ info.publishName?.slice(-2) }}</span>-->
      <!--            </div>-->
      <!--            <p class="monthList-name margin-left">{{info.publishName}}</p>-->
      <!--            <p class="monthList-position">{{info.departmentName}} —— {{info.position}}</p>-->
      <!--          </div>-->
      <!--          <div class="monthList-label" :style="planStateStyle[info.planState - 2]">-->
      <!--            {{planStateTitle[info.planState - 2]}}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
      <template v-for="(item, index) in monthList" :key="index">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item :title="index" :name="index">
            <div
              v-for="(info, i) in item"
              :key="i"
              @click="onRouterInfo(info)"
              class="
                monthList-info
                flex
                padding
                flex
                align-center
                justify-between
                padding-right-xl
                cursor-pt
              "
            >
              <div class="flex align-center">
                <div class="monthList-avatar flex align-center justify-center">
                  <img v-if="info?.avatar" :src="info.avatar" />
                  <span v-else> {{ info.publishName?.slice(-2) }}</span>
                </div>
                <p class="monthList-name margin-left">{{ info.publishName }}</p>
                <p class="monthList-position">
                  {{ info.departmentName }} —— {{ info.position }}
                </p>
              </div>
              <div
                class="monthList-label"
                :style="planStateStyle[info.checkResult]"
              >
                {{ planStateTitle[info.checkResult] }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { selectList } from '@/api/user'
import { ElLoading } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'ApprovalManagement',
  setup () {
    const editableTabsValue = ref('3')
    const router = useRouter()
    const route = useRoute()
    const monthList = ref({})

    const store = useStore()
    const radio = ref('')
    const activeNames = ref([])
    const selectionTime = ref('')
    const service = {
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    }
    // 获取待审批的数据
    const getMonth = () => {
      radio.value = ''
      selectionTime.value = ''
      const loading = ElLoading.service({
        text: '加载中',
        ...service
      })
      const { userid } = store.state.user
      selectList({
        userid: userid
      }).then((res) => {
        loading.close()
        if (res.code === 200) {
          monthList.value = res.data
          const arr = []
          for (const key in res.data) {
            arr.push(key)
          }
        }
      })
    }
    const handleChange = (e) => {
      console.log(e)
    }
    // 重置
    const replacement = () => {
      getMonth()
    }
    // 获取待审批的数据
    const getMonthList = () => {
      const loading = ElLoading.service({
        text: '加载中',
        ...service
      })
      const { userid } = store.state.user
      selectList({
        userid: userid,
        checkResult: radio.value ? radio.value : '',
        month: selectionTime.value ? selectionTime.value : ''
      }).then((res) => {
        loading.close()
        if (res.code === 200) {
          radio.value = ''
          monthList.value = res.data
          const arr = []
          for (const key in res.data) {
            arr.push(key)
          }
          if (!sessionStorage.getItem('activeNames')) {
            activeNames.value = ['', arr[0]]
          }
          activeNames.value = ['', arr[arr.length - 1]]
        }
      })
    }
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const time = y + '-' + m
        selectionTime.value = time
      }
    }
    const inquire = () => {
      getMonthList()
    }
    const planStateTitle = ['待审核', '已审核', '已拒绝', '审批中']
    const planStateStyle = [
      {
        borderColor: '#ffe896',
        background: '#FFFBE6',
        color: '#ffad0b'
      },
      {
        borderColor: '#b7eb8f',
        background: '#F6FFED',
        color: '#52c41a'
      },
      {
        borderColor: '#ffa39e',
        background: '#FFF1F0',
        color: '#f5222d'
      },
      {
        borderColor: '#f98e27',
        background: '#FFF1F0',
        color: '#f98e27'
      }
    ]
    const onRouterInfo = (info) => {
      console.log()
      const { planState, id, month } = info
      sessionStorage.setItem('activeNames', JSON.stringify(activeNames.value))
      router.push({
        path: '/performanceDetails',
        query: {
          planState,
          id,
          isSuperior: true,
          month
        }
      })
    }
    onMounted(() => {
      console.log(JSON.parse(sessionStorage.getItem('activeNames')))
      if (sessionStorage.getItem('activeNames')) {
        activeNames.value = JSON.parse(sessionStorage.getItem('activeNames'))
      }
      getMonth()
    })

    return {
      monthList,
      editableTabsValue,
      router,
      route,
      planStateTitle,
      planStateStyle,
      onRouterInfo,
      radio,
      selectionTime,
      inquire,
      timing,
      replacement,
      activeNames,
      handleChange
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.parcel {
  .immobilization {
    position: absolute;
    top: 100px;
    width: 100%;
    .radioxz {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #d3d8dd;
      display: flex;
      .radios {
        width: 25%;
        height: 30px;
        line-height: 64px;
      }
      .cxbd {
        margin-left: 100px;
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #1890ff;
        border: 2px solid #1890ff;
        margin-top: 14px;
        border-radius: 13px;
        cursor: pointer;
      }
    }
  }
  .monthList {
    &-info {
      margin-bottom: 8px;
      border-radius: 8px;
      background: #fff;
      height: 74px;
      box-sizing: border-box;
    }

    &-name {
      font-size: 16px;
      margin-right: 40px;
    }
    &-label {
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 4px;
      border: 1px solid;
    }
    &-position {
      font-size: 20px;
    }

    &-avatar {
      width: 42px;
      height: 42px;
      border-radius: 6px;
      background: #3296fa;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }

      span {
        color: #fff;
      }
    }
  }
  .el-collapse-item__header {
    padding-left: 30px;
  }
}
</style>
